import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import * as XLSX from "xlsx";
export default {
  data: () => ({
    name: "BulkInventoryMoves",
    mainPage: '',
    subPage: '',
    pageUrl: '',
    backSlash: true,
    favouriteList: [],
    showAdd: false,
    userId: EncryptUtility.localStorageDecrypt("userID"),
    refresh: true,
    selectedProject: '',
    projectData: [],
    selectedBusiness: '',
    businessData: [],
    projectSelected: false,
    selectedWarehouse: '',
    warehouseData: [],
    businessSelected: false,
    selectedMoveFrom: '',
    moveFromData: [],
    warehouseSelected: false,
    selectedMoveTo: '',
    moveToData: [],
    moveFromSelected: false,
    checkNew: false,
    validBin: false,
    newBin: '',
    binList: [],
    lpnsToMove: '',
    moveToSelected: false,
    lpnCount: 0,
    lpnDialog: false,
    lpnDetails: [],
    lpnData: [],
    excelName: '',
    json_fields: {
      "RLP ID": "license_plate",
      Reason: "comment",
    },
    lpnHeaders: [
      {
        text: "RLP ID",
        align: "start",
        value: "license_plate",
        class: "primary customwhite--text",
      },
      {
        text: "Reason",
        value: "comment",
        class: "primary customwhite--text",
      },
    ],
  }),
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.favouriteList = data.favouriteList;
    this.showAdd = data.showAdd;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
    this.getProjectList();
    // Hide bin history
    document.getElementById('bin-input').setAttribute('autocomplete', 'off');
  },
  methods: {
    //Get list of projects
    getProjectList() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/cp/project_header?pass=1&user_id=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.projectData = responseData.ProjectInfo;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Get list of businesses
    getBusinessList(project) {
      let obj = {
        UserId: this.userId,
        ProjKey: project,
        BuKey: 0,
        IncludeEmptyBU: 0,
        WithinSameLocation: 1,
        loca_key: 0,
      };
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .post("/co/business_getbyId", obj)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.businessData = responseData;
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        });
    },
    //Get list of warehouses
    getWarehouseList(project, business) {
      let obj = {
        UserId: this.userId,
        proj_key: project,
        bu_key: business,
        pass: 0,
      };
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .post("/co/ware_header", obj)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.warehouseData = responseData;
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        });
    },
    //Get list of move from locations
    getMoveFromList(project, business, warehouse) {
      let obj = {
        UserId: this.userId,
        proj_key: project,
        bu_key: business,
        ware_key: warehouse,
      };
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .post("/ut/bulk_move_from_list", obj)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.moveFromData = responseData;
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        });
    },
    //Get list of move to locations
    getMoveToList(business, fromLoc) {
      let obj = {
        UserId: this.userId,
        bu_key: business,
        from_loc: fromLoc,
      };
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .post("/ut/bulk_move_to_list", obj)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.moveToData = responseData;
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        });
    },
    //Get Page Action and name
    //On Click of Favorite icon
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //On project select
    onProjectSelect() {
      this.getBusinessList(this.selectedProject);
      this.projectSelected = true;
      this.businessSelected = false;
      this.selectedBusiness = '';
      this.warehouseSelected = false;
      this.selectedWarehouse = '';
      this.moveFromSelected = false;
      this.selectedMoveFrom = '';
      this.moveToSelected = false;
      this.selectedMoveTo = '';
      this.lpnsToMove = '';
      this.lpnCount = 0;
    },
    //On business select
    onBusinessSelect() {
      this.getWarehouseList(this.selectedProject, this.selectedBusiness);
      this.businessSelected = true;
      this.warehouseSelected = false;
      this.selectedWarehouse = '';
      this.moveFromSelected = false;
      this.selectedMoveFrom = '';
      this.moveToSelected = false;
      this.selectedMoveTo = '';
      this.lpnsToMove = '';
      this.lpnCount = 0;
    },
    //On warehouse select
    onWarehouseSelect() {
      this.getMoveFromList(this.selectedProject, this.selectedBusiness, this.selectedWarehouse);
      this.warehouseSelected = true;
      this.moveFromSelected = false;
      this.selectedMoveFrom = '';
      this.moveToSelected = false;
      this.selectedMoveTo = '';
      this.lpnsToMove = '';
      this.lpnCount = 0;
    },
    //On move from select
    onMoveFromSelect() {
      this.getMoveToList(this.selectedBusiness, this.selectedMoveFrom);
      this.moveFromSelected = true;
      this.moveToSelected = false;
      this.selectedMoveTo = '';
      this.lpnsToMove = '';
      this.lpnCount = 0;
    },
    //On move to select
    onMoveToSelect() {
      this.moveToSelected = true;
    },
    //On change of new bin/LPN checkbox
    newBinChange() {
      if (!this.checkNew) {
        this.newBin = '';
        this.binList = [];
        this.validBin = false;
      }
    },
    //On change of new bin/LPN text field
    binTextChange() {
      this.newBin = this.newBin.toUpperCase();
      if (this.newBin.length < 2) {
        this.binList = [];
        this.validBin = false;
      }
      else {
        let obj = {
          UserId: this.userId,
          SearchStr: this.newBin,
          proj_key: this.selectedProject,
        };
        this.axios
          .post("/ut/bulk_move_get_bins", obj)
          .then((response) => {
            if (response.status == 200) {
              let responseData = JSON.parse(response.data.body.message);
              this.binList = responseData;
              this.validBin = this.checkValidBin();
              // if
            } else {
              var Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
          })
          .catch((error) => {
            var Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          });
      }
    },
    //Check for valid bin upon typing
    checkValidBin() {
      for (var i = 0; i < this.binList.length; i++) {
        if (this.binList[i].bin === this.newBin) {
          return true;
        }
      }
      return false;
    },
    //On bin select
    binSelect(bin) {
      this.newBin = bin.bin;
      this.validBin = true;
      this.binList = [];
    },
    //On change of LPN text area
    lpnFieldUpdate() {
      this.lpnsToMove=this.lpnsToMove?.toUpperCase();
      document.getElementById('excelUpload').value = '';
      this.lpnsToMove === '' ? this.lpnCount = 0 : this.lpnCount = this.lpnsToMove.split(',').length;

    },
    //Click file input upon button click
    clickInput() {
      document.getElementById('excelUpload').click();
    },
    //Upload LPN excel sheet
    uploadFiles() {
      let file = document.getElementById('excelUpload').files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const result = e.target.result;
          const workbook = XLSX.read(result, { type: 'binary' });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          this.lpnsToMove = '';
          // start with second row (ignore headers)
          for (var i = 1; i < data.length; i++) {
            // append first column value to text area
            if (data[i][0] !== undefined) {
              if (this.lpnsToMove !== '') {
                this.lpnsToMove += ',';
              }
              this.lpnsToMove += data[i][0];
            }
          }
          this.lpnFieldUpdate();
        }
        reader.readAsBinaryString(file);
      }
    },
    //Reset Function
    resetFunction() {
      this.selectedProject = '';
      this.selectedBusiness = '';
      this.projectSelected = false;
      this.selectedWarehouse = '';
      this.businessSelected = false;
      this.selectedMoveFrom = '';
      this.warehouseSelected = false;
      this.selectedMoveTo = '';
      this.moveFromSelected = false;
      this.checkNew = false;
      this.validBin = false;
      this.newBin = '';
      this.binList = [];
      this.lpnsToMove = '';
      this.moveToSelected = false;
      this.lpnCount = 0;
      this.getProjectList();
    },
    //Submit details
    submitDetails() {
      let lpList = this.lpnsToMove.replaceAll("\n", "").trim().split(',');
      let jsonArray = [];
      for (var i = 0; i < lpList.length; i++) {
        jsonArray.push({ "LP": lpList[i] });
      }
      let lpJson = JSON.stringify(jsonArray);
      let obj = {
        user_key: this.userId,
        proj_key: this.selectedProject,
        bu_key: this.selectedBusiness,
        ware_key: this.selectedWarehouse,
        from_loc: this.selectedMoveFrom,
        to_loc: this.selectedMoveTo,
        to_bin: this.checkNew ? this.newBin : '',
        retain_current_binlpn: !this.checkNew,
        LP_JSON: lpJson
      };
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .post("/ut/bulk_move_submit", obj)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);       
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);          
            if (responseData !=null) {              
              this.lpnData = responseData;
              this.lpnDialog = true;
            }
           else{            
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.resetFunction()
           }

          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        });
    },
    //Cancel details
    cancelDetails() {
      this.resetFunction();
    },
    // File name for Downloading excel
    fileName() {
      this.excelName =
        "Bulk_Inventory_Moves_" +
        new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") +
        ".xls";
    },
  },
  components: {
    breadcrumbComp,
  },
};
